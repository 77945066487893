import { useState } from "react";

import loginImage from "../assets/login-icon.svg";
import "../styles/Header.css";

function Header({ assetsPath }) {
  const [hover, setHover] = useState(false);

  console.log(assetsPath)

  return (
    <header className="header">
      <nav>
        <div>
          <div className="dropdown">
            <span className="dropbtn" onClick={() => setHover(!hover)}>
              <img
                className="dropbtn hamburger-menu"
                alt="hamburger-menu"
                src={`${assetsPath}assets/icons/hamburger-menu.png`}
              />
            </span>

            {hover && (
              <div className="text dropdown-menu">
                <a href="https://shows.fanflex.com/venues">artist</a> <br />
                <a href="https://shows.fanflex.com/venues">venues</a> <br />
                <a href="https://fanflex.com/about-us/">about us</a> <br />
                <a href="https://shows.fanflex.com/venues">book now</a>
              </div>
            )}

            <img
              className="image-top"
              alt="fanflex hero"
              role="presentation"
              src={`${assetsPath}assets/images/fanflex-hero.png`}
            />
          </div>
          <a href="https://shows.fanflex.com/venues">
            <div className="login-right">
              <span>Login</span>
              <img alt="login" src={assetsPath && assetsPath !== "/" ? assetsPath + loginImage : loginImage} />
            </div>
          </a>
        </div>
      </nav>

      <div className="buttons-container buttons-container--top">
        <a href="https://shows.fanflex.com/venues">
          <img
            className="button-artist no-hover"
            alt="button-artist"
            src={`${assetsPath}assets/images/artist-button.png`}
          />
          <img
            className="button-artist button-hover"
            alt="button-artist"
            src={`${assetsPath}assets/images/button-artist-hover.png`}
          />
        </a>
        <a href="https://shows.fanflex.com/venues">
          <img
            className="button-venue no-hover"
            alt="button-venue"
            src={`${assetsPath}assets/images/venue-button.png`}
          />
          <img
            className="button-venue button-hover"
            alt="button-venue"
            src={`${assetsPath}assets/images/button-venue-hover.png`}
          />
        </a>
      </div>

      <div className="texts-under-button">
        <div className="left-side">
          <div>
            <p>
              Totally free
              <img src={`${assetsPath}assets/icons/asterisk.png`} alt="asterisk" />
            </p>
          </div>
          <div>
            <p>
              DIY booking
              <img src={`${assetsPath}assets/icons/asterisk.png`} alt="asterisk" />
            </p>
          </div>
          <div>
            <p>
              Keep 100% of the money
              <img src={`${assetsPath}assets/icons/asterisk.png`} alt="asterisk" />
            </p>
          </div>
          <div>
            <p>
              Own your fan data
              <img src={`${assetsPath}assets/icons/asterisk.png`} alt="asterisk" />
            </p>
          </div>
        </div>
        <div className="right-side">
          <div>
            <p>
              <img src={`${assetsPath}assets/icons/asterisk.png`} alt="asterisk" />
              Save 100's of hours on bookings
            </p>
          </div>
          <div>
            <p>
              <img src={`${assetsPath}assets/icons/asterisk.png`} alt="asterisk" />
              Open doors to new audiences
            </p>
          </div>
          <div>
            <p>
              <img src={`${assetsPath}assets/icons/asterisk.png`} alt="asterisk" />
              Increase food and beverage sales
            </p>
          </div>
          <div>
            <p>
              <img src={`${assetsPath}assets/icons/asterisk.png`} alt="asterisk" />
              Fill slow or dark nights
            </p>
          </div>
        </div>
      </div>
      <div className="book-to-rock">
        <p>
          <a href="https://shows.fanflex.com/venues"><span>book now</span></a> to rock FanFlex venues
        </p>
      </div>
    </header>
  );
}

export default Header;
